<template>
  <div>
    <div class="type-box">
      <div
        style="width: 150px"
        v-for="(item, index) in [
          '部门问题点',
          '近三年各部门问题点',
          '近三年内审情况',
        ]"
        :key="index"
        class="_TypeBox"
        :class="{
          action: typeBtnIndex == index,
        }"
        @click="changeTypeBtn(index)"
      >
        {{ item }}
      </div>
      <div class="_Select" v-if="typeBtnIndex == 0">
        <span>年份：</span>
        <el-date-picker
          v-model="year"
          format="yyyy"
          value-format="yyyy"
          type="year"
          placeholder="选择年"
          @change="changYear"
        >
        </el-date-picker>
      </div>
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="export"
        icon="el-icon-upload2"
        @click="exportExcel()"
        >导出</el-button
      >
    </div>
    <el-table
      v-if="typeBtnIndex == 0"
      :data="isExportExcel?tableData:tableData.slice(start, end)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 98%"
      id="table"
      :height="elementHeight"
      border
    >
      <el-table-column type="index" width="100" label="序号"> </el-table-column>
      <el-table-column prop="compName" label="公司"> </el-table-column>
      <el-table-column prop="unqualifiedCount" label="轻微不符合项">
      </el-table-column>
      <el-table-column prop="correctCount" label="纠正项"> </el-table-column>
      <el-table-column prop="improveCount" label="建议项"> </el-table-column>
      <el-table-column prop="totalCount" label="合计"> </el-table-column>
    </el-table>
    <el-table
      v-if="typeBtnIndex == 1"
      :data="isExportExcel?tableData:tableData.slice(start, end)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 98%"
      id="table"
      :height="elementHeight"
      border
    >
      <el-table-column type="index" width="100" label="序号"> </el-table-column>
      <el-table-column prop="compName" label="项目"> </el-table-column>
      //one-1/tow-2/three-3
      <el-table-column
        prop="threeYearFailCount"
        :label="new Date().getFullYear() - 3 + '年'"
      >
      </el-table-column>
      <el-table-column
        prop="twoYearFailCount"
        :label="new Date().getFullYear() - 2 + '年'"
      >
      </el-table-column>
      <el-table-column
        prop="oneYearFailCount"
        :label="new Date().getFullYear() - 1 + '年'"
      >
      </el-table-column>
    </el-table>
    <el-table
      v-if="typeBtnIndex == 2"
      :data="isExportExcel?tableData:tableData.slice(start, end)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 98%"
      id="table"
      :height="elementHeight"
      border
    >
      <el-table-column type="index" width="100" label="序号"> </el-table-column>
      <el-table-column prop="name" label="项目"> </el-table-column>
      <el-table-column
        prop="threeYearCount"
        :label="new Date().getFullYear() - 3 + '年'"
      >
      </el-table-column>
      <el-table-column
        prop="twoYearCount"
        :label="new Date().getFullYear() - 2 + '年'"
      >
      </el-table-column>
      <el-table-column
        prop="oneYearCount"
        :label="new Date().getFullYear() - 1 + '年'"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      class="_Pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import { get } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'
export default {
  data() {
    return {
      elementHeight: 0,
      typeBtnIndex: 0,
      tableData: [],
      company: '',
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      year: new Date().getFullYear() + '',
      count:0,
      isExportExcel: false,
    }
  },
  mounted() {
    this.getElementHeight()
    this.changeTypeBtn(0)
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 120)
      })
    },
    changYear() {
      this.changeTypeBtn(this.typeBtnIndex)
    },
    changeTypeBtn(e) {
      this.currentPage = 1
      this.typeBtnIndex = e
      var url = ''
      switch (e) {
        case 0:
          url = '/api/HomePageData/GetQuesByYear?Year=' + this.year
          break
        case 1:
          url = '/api/HomePageData/GetPastThreeYearQues'
          break
        case 2:
          url = '/api/HomePageData/GetPastThreeYearAudit'
          break
      }
      this.tableData = []
      get(url).then((res) => {
        if (res.code == 200) {
          if (this.typeBtnIndex == 2) {
            this.tableData.push(res.data.audit)
            this.tableData.push(res.data.dept)
            this.tableData.push(res.data.file)
            this.tableData.push(res.data.post)
            res.data.audit.name = '累计检查(项数)'  
            res.data.dept.name = '审核部门/生产作业场所(个数)匈馋抱榜'
            res.data.file.name = '查阅文件和记录份数)'
            res.data.post.name = '岗位(个数)'
            console.log(this.tableData)
            return
          }
          this.tableData = res.data
          this.count = res.count
        }
      })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    exportExcel() {
      this.isExportExcel = true;
      this.$nextTick(() =>{
        exportExcel('#table', '公司部门审核统计表.xlsx')
        this.isExportExcel = false;
      })
    },
  },
}
</script>

<style lang="less" scoped >
.type-box {
  position: relative;
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  .export {
    position: absolute;
    right: 30px;
  }
}
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
</style>